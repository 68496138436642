import './script.js';
import './analytics.js';

function get_volunteer_opps(callback) {
	let http_req = new XMLHttpRequest();
	http_req.onreadystatechange = () => {
		if (http_req.readyState === 4) {
			try {
				callback(JSON.parse(http_req.response));
			} catch(err) {
				callback([]);
				console.warn("JSON parse error:");
				console.error(err);
				document.getElementById("volunteer_opportunities_sidebar").innerHTML += '<div class="center"><h4>There was a problem fetching volunteer opportunities.</h4>';
				document.getElementById("volunteer_opportunities_sidebar").innerHTML += '<h6>Please check your network connection and try again.</h6></div>';
				document.getElementsByClassName("fingerprint-spinner")[0].remove();
			}
		}
	};
	http_req.open("GET", 'https://script.google.com/macros/s/AKfycbyogR3bQAX8mg4_2Vcs79Yx48FlltuRS8-mSC_Xv3D3sd6SI1Ae7wb1-H_IalrvXkHI/exec');
	http_req.send();
}

function get_opp_element_html(opp) {
	const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	let datestr = 'Ongoing opportunity';
	if (opp["date"] != 'Ongoing') {
		if (opp["date"].toString().indexOf("=") >= 0) {
			let dates = opp["date"].split("=");
			let date1 = new Date(dates[0]);
			let date2 = new Date(dates[1]);
			datestr = `${WEEKDAYS[date1.getDay()]} ${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()} - ${WEEKDAYS[date2.getDay()]} ${date2.getMonth() + 1}/${date2.getDate()}/${date2.getFullYear()}`;
		} else {
			let date = new Date(opp["date"]);
			datestr = `${WEEKDAYS[date.getDay()]} ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
		}
	}
	let html = `<div class="sidebar_element structured_sidebar_element">
		<p class="sse_item title">${opp["name"]}</p>
		<!-- <p class="sse_item organization">${opp["org"]}</p> -->
		<p class="sse_item location"><a href="https://www.google.com/maps?q=${opp["location"].replaceAll(" ", "+")}" target="blank">${opp["location"]}</a></p>
		<hr>
		<p class="sse_item daterange">${datestr}</p>`;
	if (opp["link"] != "") {
		html += `<p class="sse_item link"><a target="_blank" onclick="analytics_record_event('service_opportunity_clicked', { opportunity_name: '${opp["name"]}', opportunity_url: '${opp["link"]}' });" href="${opp["link"].replaceAll("javascript:", "")}">${opp["link"].replace("http://", "").replace("https://", "").replace("www.", "").replace("mailto:", "").replace("tel:", "").replaceAll("javascript:", "")}</a></p>`;
	} else {
		html += `<p class="sse_item link"><a target="_blank" onclick="analytics_record_event('service_opportunity_clicked', { opportunity_name: '${opp["name"]}', opportunity_url: '${opp["email"]}' });" href="mailto:${opp["email"]}">Contact ${opp["email"]}</a></p>`;
	}
	// html += `<p class="sse_item email">Contact: ${opp["contact"]} (<a class="break_all" href="mailto:${opp["email"]}">${opp["email"]}</a>)</p>`
	if (opp["more_info"] != "") {
		html += `<p class="sse_item info">Additional Info: ${opp["more_info"]}</p>`;
	}
	html += `</div>`;
	return html;
}

function is_date_in_past(date) {
	let now = new Date();
	date.setHours(23, 59, 59);
	return date < now;
}

window.addEventListener("DOMContentLoaded", () => {
	get_volunteer_opps((opps) => {
		opps.sort(function(first, second) {
			// If dates are ongoing they should be last
			if ((first["date"] == 'Ongoing') && (second["date"] == 'Ongoing'))
				return 0;
			if (first["date"] == 'Ongoing')
				return 1;
			if (second["date"] == 'Ongoing')
				return -1;

			// Here so that when we use the date later, it isn't the changed date.
			// If we have a date range, the date range should still be present in `get_opp_element_html`
			// rather than the first date, like we set it lower.
			let first_date = first["date"];
			let second_date = second["date"];

			let first_date_object = new Date();
			let second_date_object = new Date();
			
			// For each date, if the start date has already passed sort by the end date, otherwise sort by the start date
			// If it doesn't have a start / end date, sort by the date given.

			if ((first_date.indexOf("=") >= 0) || (second_date.indexOf("=") >= 0)) {
				if (first_date.indexOf("=") >= 0) {
					let split_first_date = first_date.split("=");
					first_date_object = new Date(split_first_date[0]);
					if (is_date_in_past(first_date_object))
						first_date_object = new Date(split_first_date[1]);
				} else { first_date_object = new Date(first_date); }
	
				if (second_date.indexOf("=") >= 0) {
					let split_second_date = second_date.split("=");
					second_date_object = new Date(split_second_date[0]);
					if (is_date_in_past(second_date_object))
						second_date_object = new Date(split_second_date[1]);
				} else { second_date_object = new Date(second_date); }
			} else {
				if (first_date.indexOf("=") >= 0) first_date = first_date.split("=")[0]; // This 0 means sort by the first date
				if (second_date.indexOf("=") >= 0) second_date = second_date.split("=")[0];

				first_date_object = new Date(first_date);
				second_date_object = new Date(second_date);
			}

			return first_date_object - second_date_object;
		});
		for (let opp of opps) {
			document.getElementById("volunteer_opportunities_sidebar").innerHTML += get_opp_element_html(opp);
		}
		document.getElementsByClassName("fingerprint-spinner")[0].remove();
	});

	// TEMP: Medal list recipients
	// TODO: Consolidate this and the medal-list.html scripts
	function title_case(str) {
		let outstr = "";

		let lastchar = undefined;
		for (let char of str) {
			if (!lastchar || lastchar == "-" || lastchar == " ")
				outstr += char.toUpperCase();
			else outstr += char.toLowerCase();
			lastchar = char;
		}

		return outstr;
	}
	const fetch_controller = new AbortController();
	const signal = fetch_controller.signal;
	// https://stackoverflow.com/a/12646864
	const shuffle = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}

	fetch("resources/MedalList.txt", { signal })
		.then((response) => { return response.text(); })
		.then((medal_list) => {
			let names = medal_list.replaceAll("\r", "").replaceAll("\t", " ").split("\n");
			for (let idx = 0; idx < names.length; idx++) {
				names[idx] = title_case(names[idx]);
			}
			
			let medal_list_name = document.getElementById("medal_list_name");
			const characters = "ABCDEFGHIJKLMNOPQRSTUVQXYZ";
			const get_random_string = (length = 5) => {
				let str = "";
				str += characters.toUpperCase()[Number.parseInt(Math.random() * characters.length)];
				for (let idx = 1; idx < length - 2; idx++) str += characters.toLowerCase()[Number.parseInt(Math.random() * characters.length)];
				str += " ";
				str += characters.toUpperCase()[Number.parseInt(Math.random() * characters.length)];
				return str;
			};
			const interval = 50;
			let random_count = 0;
			let pause_count = 2500;
			let pause_left = 0;
			let nextname = names[Number.parseInt(Math.random() * names.length)];
			setInterval(() => {
				if (pause_left >= 0) pause_left -= interval;
				else if (random_count >= 0) {
					medal_list_name.innerText = get_random_string(nextname.length);
					random_count--;
				} else {
					medal_list_name.innerText = nextname;
					nextname = names[Number.parseInt(Math.random() * names.length)];
					pause_left = pause_count;
					random_count = 5;
				}
			}, interval);
		})
		.catch((error) => {
			console.error("Medal list error:", error);
		});
});