function close_all_modals() {
	modal_forms.style.display = "none";
	modal_about.style.display = "none";
}

function init() {
	const header_forms = document.getElementById("header_forms");
	const header_about = document.getElementById("header_about");

	const modal_forms = document.getElementById("modal_forms");
	const modal_about = document.getElementById("modal_about");
	const close_modals = document.getElementsByClassName("close_modal");
	
	const menu_icon = document.getElementsByClassName("menu_icon")[0];
	const details = document.getElementsByTagName("details");
	const header = document.getElementsByTagName("header")[0];

	const update_time = document.getElementById("update_time");

	menu_icon.addEventListener("click", () => {
		if (header.dataset.expanded == "true") {
			header.dataset.expanded = "false";
		} else {
			header.dataset.expanded = "true";
		}
	});

	for (let idx = 0; idx < close_modals.length; idx++) {
		close_modals.item(idx).addEventListener("click", (evt) => evt.target.parentElement.parentElement.style.display = "none");
	}

	header_forms.addEventListener("click", () => {
		modal_about.style.display = "none";
		modal_forms.style.display = "flex";
		header.dataset.expanded = "false";
	});
	header_about.addEventListener("click", () => {
		modal_forms.style.display = "none";
		modal_about.style.display = "flex";
		header.dataset.expanded = "false";
	});
	document.body.addEventListener("keyup", (evt) => {
		if (evt.key == "Escape" || evt.key == "Esc") {
			close_all_modals();
		}
	}, true);

	window.addEventListener("beforeprint", () => {
		for (let idx = 0; idx < details.length; idx++) {
			details.item(idx).setAttribute('open', 'true');
		}
	});
	window.addEventListener("afterprint", () => {
		for (let idx = 0; idx < details.length; idx++) {
			details.item(idx).removeAttribute('open');
		}
	});

	let modified_date = new Date(document.lastModified);
	update_time.innerText = (modified_date.getMonth() + 1) + "/" + modified_date.getDate() + "/" + modified_date.getFullYear();
}

window.addEventListener("DOMContentLoaded", init);