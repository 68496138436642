function analytics_ignore() { return (localStorage.getItem("analytics-ignore") == "true" || false); }

function analytics_setup() {
	let site_loaded = (sessionStorage["siteloaded"] == "true");
	let first_time = (localStorage["notfirst"] != "true");
	sessionStorage["siteloaded"] = "true";
	localStorage["notfirst"] = "true";

	let mobile = undefined, browser = "Unknown", browser_version = "Unknown", system = "Unknown", language = navigator.language || "Unknown";
	if (navigator.userAgentData) {
		mobile = navigator.userAgentData.mobile;
		system = navigator.userAgentData.platform;
		for (let brand of navigator.userAgentData.brands) {
			if (brand.brand != "Not-A.Brand" && brand.brand != "Not.A/Brand") {
				if (browser == "Unknown") browser = "";
				if (browser_version == "Unknown") browser_version = "";
				browser += brand.brand + " / ";
				browser_version += brand.version + " / ";
			}
		}
		if (browser != "Unknown") browser = browser.substring(0, browser.length - 3);
		if (browser_version != "Unknown") browser_version = browser_version.substring(0, browser_version.length - 3);
	} else {
		if (navigator.userAgent && navigator.userAgent.toLowerCase().includes('firefox') || (('netscape' in window) && / rv:/.test(navigator.userAgent))) {
			browser = "Firefox";
			let match = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
			if (match) browser_version = match[1];
			else {
				match = window.navigator.userAgent.match(/rv:\/([0-9]+)\./);
				if (match) browser_version = match[1];
			}
		} else if ((navigator.vendor && navigator.vendor.toLowerCase().indexOf("apple") > -1) ||
			((navigator.userAgent && navigator.userAgent.toLowerCase().indexOf("safari") > -1) &&
			navigator.userAgent.indexOf('CriOS') == -1 &&
			navigator.userAgent.indexOf('FxiOS') == -1)) {
			browser = "Safari";
			let match = window.navigator.userAgent.match(/Version\/([0-9]+)\./);
			if (match) browser_version = match[1];
		}
		if (navigator.userAgent.toLowerCase().includes("crios")) {
			browser = "Chrome";
			system = "iOS";
			let match = window.navigator.userAgent.match(/CriOS\/([0-9]+)\./);
			if (match) browser_version = match[1];
		}
		if (navigator.userAgent.toLowerCase().includes("fxios")) {
			browser = "Firefox";
			system = "iOS";
			let match = window.navigator.userAgent.match(/FxiOS\/([0-9]+)\./);
			if (match) browser_version = match[1];
		}
	}

	if (navigator.userAgent && (system == "Unknown" || system == "")) {
		if (navigator.userAgent.toLowerCase().includes("iphone")) system = "iOS";
		else if (navigator.userAgent.toLowerCase().includes("mac")) system = "macOS";
		if (navigator.userAgent.toLowerCase().includes("windows")) system = "Windows";
		if (navigator.userAgent.toLowerCase().includes("cros")) system = "ChromeOS";
		if (navigator.userAgent.toLowerCase().includes("android")) system = "Android";
		if (navigator.userAgent.toLowerCase().includes("linux")) system = "Linux";
	}

	if (system == "iOS") mobile = true;

	if (analytics_ignore()) document.getElementById("analytics_ignore").style.display = "block";

	analytics_record_event("page_load", {
		page: window.location.href,
		subpage: window.location.pathname.substring(1),
		referrer: document.referrer != "" ? document.referrer : undefined,
		page_width: window.innerWidth,
		page_height: window.innerHeight,
		// I got 2400.037623167038 for the height because the device pixel ratio was some 15-digit float... cast that to an int!
		screen_width: Math.round(Number.parseFloat(window.screen.width * window.devicePixelRatio)),
		screen_height: Math.round(Number.parseFloat(window.screen.height * window.devicePixelRatio)),
		screen_resolution: `${Math.round(Number.parseFloat(window.screen.width * window.devicePixelRatio))}x${Math.round(Number.parseFloat(window.screen.height * window.devicePixelRatio))}`,
		mobile: mobile,
		browser: browser,
		browser_version: browser_version,
		os: system,
		language: language,
		first_visit: first_time, // first load ever (since storage was cleared)
		first_page_load: !site_loaded // first load this session
	});
}

//                             string, object
window.analytics_record_event = (event, data = {}) => {
	const analytics_url = 'https://script.google.com/macros/s/AKfycbzF4_BL8fYMyn1HwoCjuH4dsIO5pSenne7zOn-9XY_QqEej6oIpIkp1G84rXlTFdrYlhA/exec';
	let data_str = `${(analytics_ignore() ? '&coordinator=true' : '')}&millis_since_epoch=${new Date().getTime()}&host=${window.location.host}`;

	for (let datakey of Object.keys(data))
		if (data[datakey] != undefined)
			data_str += `&${datakey.toString().replaceAll(" ", "%20")}=${data[datakey].toString().replaceAll(" ", "%20")}`;

	let http_req = new XMLHttpRequest();
	http_req.open("GET", `${analytics_url}?event=${event.toString().replaceAll(" ", "%20")}${data_str}`);
	http_req.send();
}

if (document.readyState != "loading") analytics_setup();
else document.addEventListener("DOMContentLoaded", analytics_setup);